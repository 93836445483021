<div class="allocate-wrapper" [formGroup]="form">
  <div class="allocate flex items-center">
    <div class="item grow">
      <ng-select
        formControlName="target"
        class="upsert-select"
        appendTo="body"
        [placeholder]="placeholder | translate"
        [items]="targets"
        [searchable]="true"
        [clearable]="true"
        [multiple]="true"
        [typeahead]="inputTypeahead$">
  
        <ng-template ng-option-tmp let-item="item">
          <div class="custom-option">
            <ng-container *ngTemplateOutlet="targetInfo; context: { target: item }"></ng-container>
          </div>
        </ng-template>
  
        <ng-template ng-label-tmp let-item="item">
          <div class="custom-option">
            <div class="label-wrapper">
              <ng-container *ngTemplateOutlet="targetInfo; context: { target: item }"></ng-container>
            </div>
             <div class="text-[10px] flex items-center pointer px-1" (click)="removeItem(item)">
               <span class="material-icons-outlined" role="button"> clear </span>
             </div>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="item w-[120px]" *ngIf="includePermission">
      <ng-select
        formControlName="permission"
        [items]="permissions"
        [searchable]="false"
        [clearable]="false">
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <app-i18n [key]="item"></app-i18n>
        </ng-template>
      </ng-select>
    </div>
    <div class="item">
      <button
        class="btn btn-icon btn-primary text-white"
        [tooltip]="'add' | translate"
        [disabled]="form.get('target').invalid"
        [placement]="'top'"
        appRestrictMultiSubmit
        (multiClick)="onAdd()">
        <span class="material-icons-outlined text-[14px] p-1"> add </span>
      </button>
    </div>
  </div>

  <ng-container *ngIf="sharedTemplateRef; else defaultSharedList">
    <ng-container *ngTemplateOutlet="sharedTemplateRef"></ng-container>
  </ng-container>
</div>

<ng-template #targetInfo let-target="target">
  <app-avatar size="small" [url]="target.objectAvatar" [name]="target?.objectName" *ngIf="target?.objectType === targetType.USER"></app-avatar>
  <img class="option-icon" src="assets/icons/documents/ic_team_blue.png" alt="Team" *ngIf="target?.objectType === targetType.TEAM">
  <img class="option-icon" src="assets/icons/documents/ic_group_blue.png" alt="Group" *ngIf="target?.objectType === targetType.GROUP">

  <span class="option-label">{{target?.objectName}}</span>
</ng-template>

<ng-template #defaultSharedList>
  <div class="shared-list mt-4" *ngIf="allocatedList.length">
    <div class="shared-item min-h-[42px]" *ngFor="let row of allocatedList; let index = index">
      <div class="item flex items-center grow">
        <ng-container [ngSwitch]="row.target.objectType">
          <app-avatar size="large"
            [url]="row.target?.objectAvatar"
            [name]="row.target?.objectName"
            *ngSwitchCase="targetType.USER">
          </app-avatar>
          <img class="icon-lg" src="assets/icons/documents/ic_team_blue.png" alt="Team" *ngSwitchCase="targetType.TEAM">
          <img class="icon-lg" src="assets/icons/documents/ic_group_blue.png" alt="Group" *ngSwitchCase="targetType.GROUP">
        </ng-container>

        <div class="ml-2">
          <div>{{row.target?.objectName}}</div>
          <div *ngIf="row.target.objectType === targetType.USER">
            {{row.target?.objectEmail}}
          </div>
        </div>
      </div>
      <div class="item w-[120px]" *ngIf="includePermission">
        <ng-select
          [(ngModel)]="row.permission"
          [ngModelOptions]="{standalone: true}"
          [items]="permissions"
          [searchable]="false"
          [clearable]="false"
          (change)="updateShareTarget(row)">
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <app-i18n [key]="item"></app-i18n>
          </ng-template>
        </ng-select>
      </div>
      <div class="item">
        <button class="btn btn-icon btn-delete-share" [tooltip]="'unshare' | translate" [placement]="'top'" (click)="updateShareTarget(row, true)">
          <span class="material-icons-outlined p-1"> clear </span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
