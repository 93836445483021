export const SvgIcons = [
  'company', 'dashboard', 'holiday', 'inout', 'leave', 'location', 'performance', 'project', 'resource', 'setting', 'setup_working_day',
  'team', 'time', 'user', 'wifi', 'work_outline', 'domain', 'chrome_reader_mode', 'request', 'drag_indicator', 'alarm_on', 'beach_access',
  'check_box', 'angle-right', 'slack', 'category', 'plus', 'minus', 'settings', 'chevron-left', 'chevron-right', 'summarize', 'time_to_leave',
  'house', 'houseboat', 'cottage', 'manage_accounts', 'security', 'people_alt', 'waterfall_chart', 'format_list_numbered', 'logout',
  'group_work', 'schedule', 'donut_small', 'task_mgmt', 'projects', 'active_sprint', 'releases', 'backlog', 'due_tasks', 'project_settings',
  'info-circle-fill', 'trash', 'download', 'add_folder', 'add_shortcut', 'widgets', 'version_history', 'bar', 'share', 'list', 'link', 'upload',
  'search', 'switch_account', 'space_dashboard', 'track_changes', 'perm_identity', 'border_all', 'groups', 'adjust', 'move_to', 'open_new_tab', 'folder_setting',
  'filter_alt', 'filter_alt_off', 'arrow-down', 'home', 'credit_card', 'electrical_services', 'inventory_2', 'phone_in_talk', 'refresh', 'approve', 'reject', 'waiting',
  'cancelled', 'audit', 'account_tree', 'job_title', 'onboard_management', 'user-checked', 'comment', 'male', 'female', 'endpoint' , 'release', 'sprint',
  'push_pin', 'bronze_medal', 'gold_medal', 'silver_medal', 'adjust-progress', 'move-plan', 'collapse', 'expand', 'customer', 'archive', 'rename',
  'restore', 'edit-icon', 'empty', 'rejected', 'approved', 'notification', 'project_member', 'project_setting', 'archived_project', 'task-type-sort',
  'task-priority-sort', 'task-due-date-sort', 'task-status-sort', 'task-bookmark-sort', 'task-setting-sort', 'no-notification', 'menu_0', 'menu_1', 'menu_2', 'menu_3',
  'menu_4', 'menu_4', 'menu_5', 'menu_6', 'menu_7', 'menu_8', 'menu_9', 'section_0', 'section_1', 'section_2', 'section_3', 'section_4', 'section_5', 'section_6',
  'section_7', 'section_8', 'section_9', 'section_10', 'reminder-notify', 'reminder-notify-placeholder', 'task-management-complete', 'task-management-create-sprint',
  'task-management-delete', 'task-management-edit', 'task-management-create', 'section_7', 'section_8', 'section_9', 'section_10', 'reminder-notify', 'clone_project',
  'currency_exchange', 'salary_profile', 'payroll', 'currency-red', 'currency-blue', 'action-bar-attachment', 'action-bar-link', 'action-bar-logwork', 'action-bar-reminder',
  'action-bar-subtask', 'edit' , 'copy' , 'photo_camera', 'navigate', 'clock', 'bookmark', 'unbookmark', 'arrow-drop-down' , 'person_primary', 'empty-search', 'xcorp', 'task',
  'search-xcorp-init', 'add-emoji', 'calendar', 'feedback', 'collapse_menu', 'expand_menu', 'no-permission', 'date_range_black', 'group-space', 'team-space', 'work-calendar',
  'arrow', 'at-sign', 'colon', 'num-sign', 'more-list', 'git-branch', 'detail-description', 'check-done', 'notifications_active', 'task-sort', 'arrow-up-sort', 'arrow-down-sort',
  'add-column', 'soot', 'okr-timeline', 'star', 'star-filled', 'locked', 'stt_done', 'location_setting', 'creator', 'notes', 'person_tick',
  'person_cc', 'markdown-switch', 'document-switch', 'switch-view', 'markdown-flag', 'markdown-activated', 'security_2', 'collapse_horizontal', 'expand_horizontal',
  'expand_tree', 'collapse_tree', 'add_icon', 'sub_icon', 'warning', 'lock', 'start_value', 'current_value', 'expected_value', 'sync-data',
  'risk_level_block_on_track', 'risk_level_block_medium', 'risk_level_block_completed', 'risk_level_block_high', 'risk_level_block_low','time-clock', 'time-clock-checked', 'time-clock-lock', 'risk_level_block_no_checkin',
  'empty-search-2', 'calendar_clock'
];
