import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomListColumn, IconType } from '../../_models';

@Component({
  selector: 'x-columns-dropdown',
  templateUrl: './columns-dropdown.component.html',
  styleUrls: ['./columns-dropdown.component.scss'],
})
export class ColumnsDropdownComponent {
  @Input() dropdownColumns: CustomListColumn[] = [];
  @Output() change = new EventEmitter<CustomListColumn[]>();

  keyword: string;
  type = IconType;

  onSearchColumn(keyword: string) {
    this.keyword = keyword;
  }

  updateColumn(column: CustomListColumn) {
    column.selected = !column.selected;

    this.change.emit(this.dropdownColumns);
  }
}
