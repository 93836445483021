import { TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import {
  BoardSprint, Project, SelectOption, TaskStatus, User, WorkItemPriority, WorkItemType, ProjectPlatform, ProjectMemberUser,
  BoardLabel, Metrics, TaskTrackingReportResponse, ProjectEpicModel, Sprint, Role, ProjectShort
} from '../index';
import { Group } from '../group.model';
import { Location, LocationCountry } from '../location.model';
import { ShiftProfile } from '../shift.model';
import { Team } from '../team.model';
import { TimeLogType } from '../time-log.model';
import { TypeOfLeave } from '../type-of-leave.model';
import { head } from 'lodash';
import { BooleanValue, FieldAllValue, FieldNullValue, FieldSuggestion, LogicOperator, QueryToken, tEndDateTime, tStartDateTime } from '../../_utils';
import { TaskFilterResponse } from 'src/app/site-management/tasks/task-list/_models/task-filter.models';
import * as moment from 'moment';

export class FilterObject {
  [key: string]: FilterItem<any>;
}

export class FilterItem<T> {
  key?: string | number;
  label: string;
  items?: T[] = [];
  selecteds?: T[] = [];
  bindId?: string; // Object property
  bindLabel?: string; // Object property
  selectTemplateRef?: TemplateRef<any>;
  multiple?: boolean;
  typeahead?: Subject<any>;
  hidden?: boolean;
  icon?: string;
  bindIcon?: string;
  radio?: string;
  type?: string;
  extraData?: DueDate[] | AdvanceFilter;
  disabled?: boolean;

  constructor(obj: FilterItem<T>) {
    this.key = obj.key;
    this.label = obj.label;
    this.items = obj.items || [];
    this.selecteds = obj.selecteds || [];
    this.multiple = obj.multiple;
    this.bindLabel = obj.bindLabel;
    this.bindId = obj.bindId;
    this.selectTemplateRef = obj.selectTemplateRef;
    this.typeahead = obj.typeahead;
    this.hidden = obj.hidden || false;
    this.icon = obj.icon;
    this.bindIcon = obj.bindIcon;
    this.radio = obj.radio;
  }

  firstValue?= () => {
    return head(this.selecteds)?.[this.bindId];
  }
}

export class FilterBy {
  location?: FilterItem<Location>;
  group?: FilterItem<Group>;
  team?: FilterItem<Team>;
  user?: FilterItem<User>;
  shiftProfile?: FilterItem<ShiftProfile>;
  typeOfLeave?: FilterItem<TypeOfLeave>;
  status?: FilterItem<{ name: string, value: any }>;
  year?: FilterItem<number>;
  userStatus?: FilterItem<{ name: string, value: any }>;
}

export class UserFilterBy extends FilterBy {
  group: FilterItem<Group>;
  team: FilterItem<Team>;
  employee: FilterItem<User>;
  shiftProfile: FilterItem<ShiftProfile>;
  role?: FilterItem<Role>;
}
export class TimesheetFilterBy extends FilterBy {
  project: FilterItem<ProjectShort>;
  team?: FilterItem<Team>;
  user?: FilterItem<User>;
  location: FilterItem<Location>;
  syncJiraFlg: FilterItem<{ name: string, value: boolean }>;
  internalFlg: FilterItem<{ name: string, value: boolean }>;
  type: FilterItem<TimeLogType>;
  group: FilterItem<Group>;
  // groupBy?: FilterItem<TimeLogGroupBy>;
}

export class ReleaseFilterBy extends FilterBy {
  status: FilterItem<{ name: string, value: string }>;
}

export class BoardReleaseFilterBy extends FilterBy {
  group?: FilterItem<Group>;
  team?: FilterItem<Team>;
  reporter?: FilterItem<ProjectMemberUser>;
  assignee?: FilterItem<ProjectMemberUser>;
  taskType?: FilterItem<WorkItemType>;
  epic?: FilterItem<ProjectEpicModel>;
  priority?: FilterItem<WorkItemPriority>;
  period?: FilterItem<SelectOption>;
  platform: FilterItem<ProjectPlatform>;
  label?: FilterItem<BoardLabel>;
  metric?: FilterItem<Metrics>;
  sprint?: FilterItem<Sprint>;
}

export class StorylineFilterBy extends FilterBy {
  taskType: FilterItem<WorkItemType>;
  priority: FilterItem<WorkItemPriority>;
  taskStatus: FilterItem<TaskStatus>;
  period?: FilterItem<SelectOption>;
  sprint?: FilterItem<BoardSprint>;
  group?: FilterItem<Group>;
  team?: FilterItem<Team>;
  platform?: FilterItem<ProjectPlatform>;
  taskKey?: FilterItem<TaskTrackingReportResponse>;
}

export class HistoryFilterBy extends FilterBy {
  country?: FilterItem<LocationCountry>;
}

export class SearchParam {
  projectId: number;
  page = 0;
  size = 10000;
  sort: string[];
}

export class AuditLogFilter extends FilterBy {
  action?: FilterItem<{ name: string, value: any }>;
}

export class MembersFilter extends FilterBy {
  role?: FilterItem<{ id: number, roleName: string }>;
}

export class TaskListFilterBy extends FilterBy {
  project?: FilterItem<Project>;
  reporter?: FilterItem<ProjectMemberUser>;
  assignee?: FilterItem<ProjectMemberUser>;
  cc?: FilterItem<ProjectMemberUser>;
  createdBy?: FilterItem<ProjectMemberUser>;
  group?: FilterItem<Group>;
  team?: FilterItem<Team>;
  period?: FilterItem<SelectOption>;
  priority?: FilterItem<WorkItemPriority>;
  taskKey?: FilterItem<TaskTrackingReportResponse>;
  taskStatus?: FilterItem<TaskStatus>;
  taskType?: FilterItem<WorkItemType>;
  createdAt?: FilterItem<SelectOption>;
  modifiedAt?: FilterItem<SelectOption>;
  name?: FilterItem<string>;
  statusCategory?: FilterItem<SelectOption>;
  advanceFilter?: FilterItem<TaskFilterResponse>;
  customQuery?: FilterItem<string>;
}

export type DueDate = { startDate: string; endDate: string } | string | string[];

export interface AdvanceFilterObject {
  name: string;
  description?: string;
  filter: AdvanceFilter;
}
export interface AdvanceFilter {
  logicOperator: string;
  expr: AdvanceExpFilter[];
  child?: AdvanceFilter[];
}

export interface AdvanceExpFilter {
  lhs?: string;
  operator?: string;
  rhs?: string | string[] | number | number[];
  xql?: string;
  touched?: boolean;
}

export interface FieldCompareData {
  [key: string]: string[];
}

export class AdvanceFilters {
  logicOperator: string;
  expr: AdvanceExpFilter[];
  child?: AdvanceFilters[];

  constructor(filterObject: AdvanceFilter) {
    this.logicOperator = filterObject.logicOperator;
    this.expr = filterObject.expr;
    this.child = filterObject.child?.map(item => new AdvanceFilters(item));
  }
  getQuery() {
    let query = '';
    this.expr.forEach((exp, index) => {
      if (exp?.xql) {
        if (query.length === 0) {
          query += exp.xql;
        } else {
          query += ` ${this.logicOperator} ${exp.xql}`;
        }
      } else {
        if (exp.rhs !== FieldAllValue) {
          let tempQuery = '';
          // Multi case
          if (Array.isArray(exp.rhs)) {
            const firstValue = exp.rhs[0];
            let value = '';
            if (isNaN(Number(firstValue))) {
              const rhsData = exp.rhs as string[];
              value = rhsData.reduce((acc, cur, idx) => acc + (idx === rhsData.length - 1 ? `"${cur}"` : `"${cur}", `), '');
            } else {
              const rhsData = exp.rhs as number[];
              value = rhsData.reduce((acc, cur, idx) => acc + (idx === rhsData.length - 1 ? `${cur}` : `${cur}, `), '');
            }
            tempQuery = `${exp.lhs} ${exp.operator} (${value})`;
          } else {
            // Case for createAt (toDate)
            if (exp.lhs === FieldSuggestion.CREATED_AT || exp.lhs === FieldSuggestion.MODIFIED_AT) {
              const date = exp.operator === QueryToken.LESS_THAN_OR_EQUAL
                ? moment(exp.rhs).endOf('d').utc().format()
                : moment(exp.rhs).startOf('d').utc().format();
              tempQuery = `${exp.lhs} ${exp.operator} "${date}"`;
            } else if (BooleanValue.includes(String(exp.rhs))) {
              tempQuery = `${exp.lhs} ${exp.operator} ${exp.rhs}`;
            } else if (exp.rhs === FieldNullValue || exp.rhs === null) {
              tempQuery = `${exp.lhs} ${exp.operator} ${QueryToken.NULL}`;
            } else {
              tempQuery = `${exp.lhs} ${exp.operator} "${exp.rhs}"`;
            }
          }

          if (index !== 0) {
            tempQuery = ` ${this.logicOperator} ${tempQuery}`;
          }

          query += tempQuery;
        }
      }
    });
    return query;
  }

  getChildQuery() {
    const query = [];
    this.child?.forEach((filter, index) => {
      if (filter?.child) {
        query.push(index === 0 ? `(${filter.getQuery()}` : `${this.logicOperator} (${filter.getQuery()}`);
        const grandChildrenQueries = this.getGrandChildrenQuery(filter);
        grandChildrenQueries.forEach((item, idx) => {
          query.push(item);
        });
      } else {
        query.push(index === 0 ? `(${filter.getQuery()})` : `${this.logicOperator} (${filter.getQuery()})`);
      }
    });
    return query;
  }

  getGrandChildrenQuery(filter: AdvanceFilter, query = []) {
    filter.child?.forEach((item, index) => {
      const filterItem = new AdvanceFilters(item);

      if (item?.child) {
        query.push(`${filter.logicOperator} (${filterItem.getQuery()}`);
        filterItem.getGrandChildrenQuery(item, query);
      } else {
        query.push(`${filter.logicOperator} (${filterItem.getQuery()})`);
      }
    });
    const lastQuery = query[query.length - 1];
    query[query.length - 1] = `${lastQuery})`;
    return query;
  }

  getFinalQuery() {
    const parentQuery = this.getQuery();
    const childQuery = this.getChildQuery();

    if (this.logicOperator === LogicOperator.NOT) {
      return parentQuery
        ? `${this.logicOperator}${QueryToken.LCB}${parentQuery}${QueryToken.RCB}`
        : `${this.logicOperator}${childQuery.join('')}`;
    } else if (parentQuery && childQuery.length > 0) {
      return `${parentQuery} ${this.logicOperator} ${childQuery.join(' ')}`;
    } else if (!parentQuery && childQuery.length > 0) {
      return childQuery.join(' ');
    }
    return parentQuery;
  }
}
