import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { MatMenuModule } from '@angular/material/menu';
import { DEFAULT_DIALOG_CONFIG, DialogConfig, DialogModule } from '@angular/cdk/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';

const DIALOG_DEFAULT_TOP = '1.75rem';

const NG_MATERIAL = [
  DragDropModule,
  MatIconModule,
  MatMenuModule,
  OverlayModule,
  CdkScrollableModule,
  DialogModule,
  MatTooltipModule,
];

export function dialogConfigFactory(overlay: Overlay): DialogConfig {
  const positionStrategy = overlay.position()
    .global()
    .top(DIALOG_DEFAULT_TOP)
    .centerHorizontally()

  return {
    positionStrategy: positionStrategy,
    hasBackdrop: true,
  };
}

@NgModule({
  imports: [...NG_MATERIAL],
  exports: [...NG_MATERIAL],
  providers: [
    { 
      provide: DEFAULT_DIALOG_CONFIG,
      useFactory: dialogConfigFactory,
      deps: [Overlay]
    }
  ]
})
export class NgMaterialModule {}
