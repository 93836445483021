import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { isEmpty } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, debounceTime, filter, take, takeUntil } from 'rxjs';
import { AppInjector } from 'src/app/app.module';
import { QUERY_PARAMS, QUERY_PARAMS_VALUE, TASK_MODAL_CLASS, Task } from 'src/app/shared';
import { TaskDetailComponent } from '../task-detail/task-detail.component';
import { Location } from '@angular/common';

export interface TaskParams {
  taskKey?: string;
  view?: QUERY_PARAMS_VALUE;
}

interface State {
  modalRef: BsModalRef<TaskDetailComponent>;
  viewDetail: TaskParams;
  show?: boolean;
}

@Injectable()
export class TaskQueryParamStore extends ComponentStore<State> {
  taskKey: string;
  modalService: BsModalService;

  modalRef$ = this.select((state) => state.modalRef).pipe(filter(e => !isEmpty(e)));
  viewDetail$ = this.select((state) => state.viewDetail).pipe(filter(e => !isEmpty(e)));
  show$ = this.select((state) => state.show);

  get modalRef() {
    return this.get().modalRef;
  }

  taskUpdated$: Observable<Task>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {
    super({
      modalRef: null,
      viewDetail: null,
      show: false,
    })
    this.onNavigationEnd();

    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.onNavigationEnd();
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onNavigationEnd() {
    const key: string = this.route?.snapshot?.queryParams?.[QUERY_PARAMS.task.selectedTask];
    const view: string = this.route?.snapshot?.queryParams?.[QUERY_PARAMS.task.view];

    if (!key || !view) {
      this.hideAll();
      return;
    }

    if (view === QUERY_PARAMS_VALUE.modal) {
      this.handleTaskModal(key);
      return;
    }

    if (view === QUERY_PARAMS_VALUE.detail) {
      this.patchState({ viewDetail: { taskKey: key } });
      return;
    }
  }

  hideAll() {
    this.modalRef?.hide();

    this.patchState({
      modalRef: null,
      viewDetail: { taskKey: null, view: null }
    });
  }

  handleTaskModal(key: string) {
    const bsModalElement = document.body.querySelector('bs-modal-backdrop');
    this.modalService = AppInjector.get(BsModalService);

    this.taskKey = key;

    if (bsModalElement) {
      return;
    }

    const modalRef = this.modalService.show(TaskDetailComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        taskKey: this.taskKey,
        linkable: true,
        isModal: true,
        closeable: true,
      },
      class: TASK_MODAL_CLASS,
    });

    this.patchState({
      modalRef
    })

    modalRef.content.hide.pipe(take(1)).subscribe(e => {
      this.goBack();
      this.patchState({ show: false})
    });

    this.taskUpdated$ = modalRef.content.updated.asObservable();

    this.patchState({ show: true });
  }

  showTaskDetail(taskKey: string) {
    this.navigate({
      taskKey,
      view: QUERY_PARAMS_VALUE.detail,
    })
  }

  showModal(taskKey: string) {
    this.navigate({
      taskKey,
      view: QUERY_PARAMS_VALUE.modal,
    })
  }

  goBack() {
    this.navigate({});
    return;
    // TODO there is an error with location back when froala content has video iframe
    if (window.history?.state?.navigationId === 1) {
      this.navigate({});
    } else {
      this.location.back();
    }
  }

  navigate(params: TaskParams = {}) {
    const queryParams: Params = {};
    const { taskKey, view } = params;

    if (taskKey) {
      queryParams[QUERY_PARAMS.task.selectedTask] = taskKey;
    }
    if (view) {
      queryParams[QUERY_PARAMS.task.view] = view;
    }

    this.router.navigate(
      [location.pathname],
      { queryParams, queryParamsHandling: '' }
    );
  }
}
