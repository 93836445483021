import { createFeature, createReducer, on } from '@ngrx/store';
import { featureKey } from './dynamic-field.selectors';
import { DynamicFieldState } from './dynamic-field.models';
import { taskFieldAdapter, taskFieldState } from './dynamic-field.entity';
import { DynamicFieldActions } from '.';

const initialState: DynamicFieldState = {
  fields: [],
  taskFields: taskFieldState,
};

const dynamicFieldReducer = createReducer(
  initialState,
  on(DynamicFieldActions.setFields, (state, { fields }) => ({ ...state, fields })),
  on(DynamicFieldActions.setTaskDFields, (state, { data }) => {
    return { ...state, taskFields: taskFieldAdapter.setAll(data, state.taskFields) }
  }),
);

export const dynamicFieldFeature = createFeature({
  name: featureKey,
  reducer: dynamicFieldReducer
});
