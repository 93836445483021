import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { CustomDropdownService } from './custom-dropdown.service';
import { IconType } from '../../_models';
import { BsPlacement } from '../../_utils';

@Component({
  selector: 'x-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
  providers: [CustomDropdownService],
})
export class CustomDropdownComponent {
  @Input() icon = 'more_horiz';
  @Input() iconType = IconType.MAT;
  @Input() showArrow = false;
  @Input() setBtnWidth = true;
  @Input() setBtnHeight = true;
  @Input() border = false;
  @Input() placement: BsPlacement;

  @ViewChild('dropdown') dropdown: TemplateRef<any>;
  @ViewChild('button') button: ElementRef<HTMLElement>;

  type = IconType;

  constructor(private dropdownService: CustomDropdownService) {
    this.dropdownService.placement = this.placement;
  }

  onClick() {
    if (this.dropdownService.isOpen) {
      this.dropdownService.hide();
      return;
    }
    this.dropdownService.open(this.button.nativeElement, this.dropdown);
  }
}
