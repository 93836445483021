import { Injectable } from '@angular/core';
import { XOverlayService } from 'src/@xcorp/services/overlay/x-overlay.service';
import { CustomDropdownMenuComponent } from './custom-dropdown-menu/custom-dropdown-menu.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { BsPlacement, PositionOverlay, WithOffset } from '../../_utils';

@Injectable({
  providedIn: 'any'
})
export class CustomDropdownService extends XOverlayService<CustomDropdownMenuComponent, any> {
  placement: BsPlacement = BsPlacement.left;

  override component(): ComponentPortal<CustomDropdownMenuComponent> {
    return new ComponentPortal(CustomDropdownMenuComponent);
  }

  override position(): ConnectionPositionPair[] {
    const bottomRight = WithOffset(PositionOverlay.bottomRight, -40, 10);
    const topRight = WithOffset(PositionOverlay.topRight, -40, -10);
    const bottomLeft = WithOffset(PositionOverlay.bottomLeft, 0, 10);
    const topLeft = WithOffset(PositionOverlay.topLeft, 0, -10);

    if (this.placement === BsPlacement.right) {
      return [
        bottomRight, bottomLeft, topRight, topLeft
      ];
    }

    return [
      bottomLeft, bottomRight, topLeft, topRight
    ];
  }

}
