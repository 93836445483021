import * as moment from 'moment';
import { PermissionChecker, Role, RolePermissionCodes } from 'src/app/shared';

export class TaskPermissionChecker extends PermissionChecker {
  get canCreate() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_CREATE] || this.canEditAnother;
  }

  get canDelete() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_DELETE] || this.canEditAnother;
  }

  get canEditComment() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT];
  }

  get canViewAnotherComment() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_VIEW_OTHER];
  }

  get canEditAnotherComment() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT_OTHER];
  }

  get canDeleteComment() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_DELETE];
  }

  get canCreateComment() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_COMMENT_CREATE];
  }

  get canEditAssignee() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_ASSIGNEE];
  }

  get canEditReporter() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_REPORTER];
  }

  get canEditCC() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_CC];
  }

  get canEditAdvance() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_ADVANCE];
  }

  get canViewAdvance() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_VIEW_ADVANCE];
  }

  get canEditPrivateFlag() {
    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_PRIVATE_FLAG];
  }

  constructor(codes: RolePermissionCodes, roles?: Role[]) {
    super(codes, roles);
  }

  canEditAfterDueDate(dueDate: string) {
    if (!dueDate || moment(dueDate).isAfter(moment())) {
      return true;
    }

    return this.visibility[this.permissionCode.TASK_MANAGEMENT_TASK_DETAIL_EDIT_AFTER_DUE_DATE];
  }

  checkAdvanceField() {
    return {
      canView: this.canViewAdvance,
      canEdit: this.canEditAdvance
    };
  }
}
