import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserSettingKey, UserSettingState } from './user-setting.model';
import { featureKey } from './user-setting.reducers';

const selectFeature = createFeatureSelector<UserSettingState>(featureKey);

export const selectUserSettingState = (key: UserSettingKey) =>
  createSelector(
    selectFeature,
    (state: UserSettingState) => state?.[key]
  );

