import { createFeature, createReducer } from '@ngrx/store';
import {
  backlogsPermissionState, PermissionState, projectSettingPermissionState, releaseDetailPermissionState, storylinePermissionState,
  taskFinderPermissionState, taskPermissionState, epicsPermissionState
} from './permission.models';
import { featureKey } from './permission.selectors';

const initialState: PermissionState = {
  task: taskPermissionState,
  backlogs: backlogsPermissionState,
  releaseDetails: releaseDetailPermissionState,
  taskFinder: taskFinderPermissionState,
  storyline: storylinePermissionState,
  projectSetting: projectSettingPermissionState,
  epics: epicsPermissionState,
};

const permissionReducer = createReducer(
  initialState,
);

export const permissionFeature = createFeature({
  name: featureKey,
  reducer: permissionReducer
});
