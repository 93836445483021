import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermissionState, ProjectSettingCategory } from './permission.models';
import { TaskPermissionChecker } from '../../tasks/_services/task-permission.service';
import { PermissionChecker } from 'src/app/shared';
import { selectProjectRoles } from '../site-management';
import { selectProjectTaskDFields } from '../dynamic-field';

export const featureKey = 'permission';
export const projectPermission: Record<number, TaskPermissionChecker> = {};
export const projectSettingPermission: Record<number, Record<string, PermissionChecker>> = {};

const selectFeature = createFeatureSelector<PermissionState>(featureKey);

const selectTask = createSelector(selectFeature, (state) => state.task);

export const selectTaskPermission = (projectId?: number) =>
  createSelector(
    selectTask,
    selectProjectRoles,
    (taskPermission, projectRoles) => {
      if (projectPermission[projectId]) {
        return projectPermission[projectId];
      }

      const roles = projectRoles[projectId];
      const permission = new TaskPermissionChecker(
        {
          editCodes: taskPermission.edit,
          editAnotherCodes: taskPermission.editAnother,
          ...taskPermission
        },
        roles
      );
      projectPermission[projectId] = permission;

      return permission;
    }
  );

export const selectProjectTaskPermission = () =>
  createSelector(
    selectTask,
    selectProjectRoles,
    (taskPermission, projectRoles) => {
      let result: {projectId: number, permission: TaskPermissionChecker}[] = [];

      for (const projectId in projectRoles) {
        const roles = projectRoles[projectId];

        result.push({
          projectId: +projectId,
          permission: new TaskPermissionChecker(
            {
              editCodes: taskPermission.edit,
              editAnotherCodes: taskPermission.editAnother,
              ...taskPermission
            },
            roles
          )
        });
      }

      return result;
    }
  );

export const selectTaskAndFieldsPermission = (projectId: number) =>
  createSelector(
    selectTaskPermission(projectId),
    selectProjectTaskDFields(projectId),
    (permission, fields) => ({ permission, fields })
  );

const selectBacklogs = createSelector(selectFeature, (state) => state.backlogs);

export const selectBacklogsPermission = createSelector(
  selectBacklogs,
  (state) =>
    new PermissionChecker({
      editCodes: state.edit,
      editAnotherCodes: state.editAnother,
      viewCodes: state.view,
      viewAnotherCodes: state.viewAnother
    })
);

const selectReleaseDetails = createSelector(
  selectFeature,
  (state) => state.releaseDetails
);

export const selectReleaseDetailsPermission = createSelector(
  selectReleaseDetails,
  (state) =>
    new PermissionChecker({
      editCodes: state.edit,
      editAnotherCodes: state.editAnother,
      viewCodes: state.view,
      viewAnotherCodes: state.viewAnother
    })
);

const selectTaskFinder = createSelector(
  selectFeature,
  (state) => state.taskFinder
);

export const selectTaskFinderPermission = createSelector(
  selectTaskFinder,
  (state) =>
    new PermissionChecker({
      editCodes: state.edit,
      viewCodes: state.view
    })
);

const selectStoryline = createSelector(
  selectFeature,
  (state) => state.taskFinder
);

export const selectStorylinePermission = createSelector(
  selectStoryline,
  (state) =>
    new PermissionChecker({
      editCodes: state.edit,
      viewCodes: state.view
    })
);

/** Project Setting */
const selectProjectSetting = createSelector(selectFeature, state => state.projectSetting);

export const selectProjectSettingPermission = (projectId: number, category: ProjectSettingCategory) =>
  createSelector(
    selectProjectSetting,
    selectProjectRoles,
    (settingPermission, projectRoles) => {
      let setting = projectSettingPermission?.[projectId]?.[category];

      if (setting) {
        return setting;
      }

      const { 
        view: viewCodes,
        viewAnother: viewAnotherCodes = [], 
        edit: editCodes,
        editAnother: editAnotherCodes = []
      } = settingPermission[category];
      const roles = projectRoles[projectId];

      setting = new PermissionChecker(
        { viewCodes, editCodes, viewAnotherCodes, editAnotherCodes },
        roles
      );
      projectSettingPermission[projectId] = {
        ...projectSettingPermission[projectId],
        [category]: setting,
      };
      return setting;
    }
  );

/** Epic */
const selectEpics = createSelector(selectFeature, (state) => state.epics);

export const selectEpicsPermission = createSelector(
  selectEpics,
  (state) => new PermissionChecker({
    viewCodes: state.view,
    editCodes: state.edit,
  })
);
