import { createAction, props } from '@ngrx/store';
import { LoadingState } from '../../models/loading';
import { EditorMode } from 'src/app/shared/_components/x-editor/x-editor';

export const loading = createAction('[App] Loading', props<LoadingState>());

export const setDefaultPageUrl = createAction(
  '[App] Set Default Page Url',
  props<{ url: string }>()
);

export const setEditorMode = createAction(
  '[App] Set Edit Mode',
  props<{ mode: EditorMode }>()
);

export const AppActions = {
  loading,
  setDefaultPageUrl,
  setEditorMode
};
