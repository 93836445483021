import { Pipe, PipeTransform } from '@angular/core';
import { CustomListColumn } from '../../_models';

@Pipe({
  name: 'isVisibleColumn'
})
export class IsVisibleColumnPipe implements PipeTransform {
  transform(keyword: string, item: CustomListColumn) {
    return !keyword || item?.name?.toLowerCase()?.includes(keyword?.toLowerCase());
  }
}


export const COLUMNS_DROPDOWN_PIPE = [
  IsVisibleColumnPipe,
];
