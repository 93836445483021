import { Component, Input, TemplateRef } from '@angular/core';
import { BaseOverlayComponent } from 'src/@xcorp/services/overlay/abtract-overlay';
import { CustomDropdownService } from '../custom-dropdown.service';

@Component({
  selector: 'x-custom-dropdown-menu',
  templateUrl: './custom-dropdown-menu.component.html',
  styleUrls: ['./custom-dropdown-menu.component.scss']
})
export class CustomDropdownMenuComponent extends BaseOverlayComponent<
  TemplateRef<any>,
  CustomDropdownService
> {}
