<x-custom-dropdown [icon]="'add-column'" [iconType]="type.LOCAL">
    <div class="header mt-2">
      <app-search-input
          (keywordChange)="onSearchColumn($event)"
        ></app-search-input>
    </div>
    <div class="option-list">
      <ng-container *ngFor="let column of dropdownColumns">
        <div
          class="custom-option"
          [ngClass]="{ 'd-none': !(keyword | isVisibleColumn : column) }"
          (click)="updateColumn(column)"
        >
          <input type="checkbox" [checked]="column?.selected" />
          <span class="checkbox-label ml-2"
            ><app-i18n [key]="column.name"></app-i18n
          ></span>
        </div>
      </ng-container>
    </div>

</x-custom-dropdown>
