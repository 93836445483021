import { isEmpty, isNull, isUndefined } from 'lodash';
import { MonoTypeOperatorFunction, Observable, filter, pipe, map } from 'rxjs';
import { ActionFetchProps } from 'src/app/core/models/store';

export function nonEmpty<T>(): MonoTypeOperatorFunction<T> {
  return pipe(
    filter((v) => !(isNull(v) || isUndefined(v)))
  );
}

export function withoutUndefined<T>(): MonoTypeOperatorFunction<T> {
  return pipe(
    filter((v) => !isUndefined(v))
  );
}

export function index<T>(idx: number): MonoTypeOperatorFunction<T> {
  return pipe(
    map((v) => v[idx])
  );
}

export function distinctStateChanged<T extends [props: ActionFetchProps, state: any]>() {
  return (observable: Observable<T>) =>
    new Observable<T>((subscriber) => {
      const subscription = observable.subscribe({
        next(res) {
          const [props, state] = res;
          if (props?.force || isEmpty(state)) {
            subscriber.next(res);
          }
        },
        error(err) {
          subscriber.error(err);
        },
        complete() {
          subscriber.complete();
        },
      });
      return () => {
        subscription.unsubscribe();
      };
    });
}
