export interface Metrics {
  id: number;
  name: string;
  unit: string;
  description?: string;
  isPercent?: boolean;
}

export const PercentMetric = {
  name: 'Percent',
  unit: '%',
}

export const DefaultMetric = {
  name: 'Default',
  unit: '%',
}

export interface MetricsCreateRequest {
  name: number;
  unit: number;
}

export interface MetricsUpdateRequest {
  name: number;
  unit: number;
  description?: number;
}

