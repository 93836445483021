import { calendarSettingColorDefault, getCalendarActionSetting } from "../../work-calendar/models/work-calendar";

export class UserSetting {
  settingKey: UserSettingKey;
  settingValue: string;
}

export class UserSettingRequest {
  key: string;
  value: string;
}

export enum UserSettingKey {
  swimlane = 'UserSettingSwimlane',
  activeBoard = 'UserSettingActiveBoard',
  backlogs = 'UserSettingBacklogs',
  releaseDetail = 'UserSettingReleaseDetail',
  epic = 'UserSettingEpic',
  userDashboard = 'UserSettingUserDashboard',
  projects = 'UserSettingProjects',
  release = 'UserSettingRelease',
  storyLine = 'UserSettingStoryLine',
  taskColumns = 'UserSettingTaskColumns',

  // search system
  menuSearch= 'menuSearch',
  userSearch= 'userSearch',
  ticketSearch= 'ticketSearch',
  cvSearch= 'cvSearch',
  groupSearch= 'groupSearch',
  teamSearch= 'teamSearch',
  projectSearch= 'projectSearch',
  salarySearch= 'salarySearch',
  scheduleSearch= 'scheduleSearch',
  payrollSearch= 'payrollSearch',
  fileSearch= 'fileSearch',

  // calendar
  calendarSetting = 'workCalendarSetting',

  // Leave module
  leaveRequest = 'UserSettingLeaveRequest',
  leaveApprove = 'UserSettingLeaveApprove',

  // showPopupJiraAccountFlg
  hidePopupJiraAccountFlg = 'hidePopupJiraAccountFlg',

  // document
  documentMode = 'UserSettingDocumentMode',

  // okr
  myOkr = 'UserSettingMyOkrSettings',
  okrCache = 'UserSettingOkrBoardCache',
}

export interface UserSettingState {
  [key: string]: string;
}

export const defaultUserSettingState = {
  [UserSettingKey.calendarSetting]: JSON.stringify({
    actions: getCalendarActionSetting(),
    colors: calendarSettingColorDefault()
  })
};
