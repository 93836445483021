import { createAction, props } from '@ngrx/store';
import { UserSetting, UserSettingKey, UserSettingRequest } from './user-setting.model';

export const getUserSetting = createAction(
  '[User Setting] Get User Setting',
  props<{payload: UserSettingKey}>()
);

export const storeUserSetting = createAction(
  '[User Setting] Get User Setting Success',
  props<{payload: UserSetting}>()
);

export const updateUserSetting = createAction(
  '[Task Management] Update User Setting',
  props<{payload: UserSettingRequest}>()
);
