import { Pipe, PipeTransform } from '@angular/core';

export interface NumberSuffixArgs {
  decimal?: number;
  suffixDecimal?: number;
  exponential?: string;
  showExponent?: boolean;
  customSuffix?: boolean;
}
@Pipe({
  name: 'numberSuffix'
})
export class NumberSuffixPipe implements PipeTransform {
  args = {
    decimal: 2,
    suffixDecimal: 1,
    exponential: '<em>e</em>',
    showExponent: true,
    customSuffix: false
  }
  transform(input: any, _args: NumberSuffixArgs = {}): any {
    const args = {
      ...this.args,
      ..._args
    }
    let exp;
    const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
    const coefficient = input < 0 ? -1 : 1;
    if (Number.isNaN(input) || (input < 1000 && input >= 0) || !this.isNumeric(input) || (input < 0 && input > -1000)) {
      if (this.isNumeric(input) && input != 0) {
        if (input * coefficient >= 0.01 || !args.showExponent) {
          if (input * coefficient >= 10) {
            return +input.toFixed(args?.suffixDecimal || 0);
          }
          return +input.toFixed(args?.decimal || 0);
        }
        return input.toExponential(2).replace('e', args?.exponential);
      } else {
        return input;
      }
    }

    input = input * coefficient;
    exp = Math.floor(Math.log(input) / Math.log(1000));
    const value = +(input * coefficient / Math.pow(1000, exp));
    const suffix = args?.customSuffix ? `<span class="number-suffix">${suffixes[exp - 1]}</span>` : suffixes[exp - 1];
    return +value.toFixed(value * coefficient >= 10 ? args?.suffixDecimal : args?.decimal) + suffix;

  }



  isNumeric(value): boolean {
    if (value < 0) value = value * -1;
    if (/^-{0,1}\d+$/.test(value)) {
      return true;
    } else if (/^\d+\.\d+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }
  // https://medium.com/@nimishgoel056/display-number-in-billion-million-thousand-using-custom-pipe-in-angular-b95bf388350a
}
