import { createReducer, on } from '@ngrx/store';
import { AppActions } from './app.actions';
import { LoadingState } from '../../models/loading';
import { EditorMode } from 'src/app/shared/_components/x-editor/x-editor';

export const appFeatureKey = 'auth';

export interface AppState {
  loading?: LoadingState;
  defaultUrlPage?: string;
  editorMode: EditorMode;
}

export const initAppState: AppState = {
  editorMode: 'view'
};

export const appReducer = createReducer(
  initAppState,
  on(AppActions.loading, (state: AppState, loading: LoadingState): AppState => {
    return { ...state, loading };
  }),
  on(AppActions.setEditorMode, (state: AppState, { mode }): AppState => {
    return { ...state, editorMode: mode };
  })
);
