<button type="button"
        #button
        class="btn btn-icon btn-dropdown default btn-light"
        [ngClass]="{
          'w-unset': !setBtnWidth || showArrow,
          'h-unset': !setBtnHeight,
          'pl-1': showArrow,
          'x-border': border
        }"
        (click)="onClick()"
        stopEvent>
  <span *ngIf="iconType === type.MAT" class="material-icons-outlined"> {{ icon }} </span>
  <mat-icon *ngIf="iconType === type.LOCAL" [svgIcon]="'add-column'"></mat-icon>
  <span *ngIf="showArrow" class="material-icons-outlined"> arrow_drop_down </span>
</button>

<ng-template #dropdown>
  <div class="custom-dropdown-menu">
    <ng-content></ng-content>
  </div>
</ng-template>
